#header {
    background-color: #161614;
}

#header-container {
    border-bottom: 1px solid #fff;
}

#header-navbar {
    min-height: 30px;
    text-transform: capitalize;
    font-weight: 400;
    font-size: 13px;
    border-top: 1px solid #fff;
}

#header-navbar .nav-link {
    margin-left: 0;
    margin-right: 10px;
    padding-left: 0;
    padding-right: 0;
    color: #fff;
    text-transform: uppercase;
    text-decoration: none;
}

#header-navbar .nav-link:hover {
    color: #5294ff;
}

#header-navbar .nav-link.active {
    color: #5294ff;
}

#grid-bg {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    background-color: #161614;
}

#grid-bg .grid-bg-col {
    border-right: 1px solid #333;
    width: calc(100% / 12);
    height: 100%;
    display: inline-block;
    box-sizing: border-box;
}

#grid-bg .grid-bg-col:last-of-type {
    border-right: 0;
}