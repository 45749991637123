@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

html, body {
    background-color: transparent !important;
    font-family: 'Montserrat' !important;
}

#app-container {
    min-height: calc(100vh - 180px);
    overflow: hidden;
    background-color: transparent;
}

#app-container a {
    color: #eee;
    text-decoration: underline;
}