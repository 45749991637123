.button-52 {
    font-size: 14px;
    font-weight: 400;
    padding: 13px 20px 13px;
    outline: 0;
    color: #000;
    border: 1px solid #bbb;
    cursor: pointer;
    position: relative;
    background-color: transparent;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.button-52:after {
    content: "";
    background-color: rgba(256, 256, 256, 0.95);
    width: 100%;
    z-index: -1;
    position: absolute;
    height: 100%;
    top: 7px;
    left: 7px;
    transition: 0.2s;
}

.button-52:hover:after {
    top: 0px;
    left: 0px;
}

@media (min-width: 768px) {
    .button-52 {
        padding: 13px 50px 13px;
    }
}