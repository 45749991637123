.entry-cal {
    border: 1px solid #666;
    height: 60px;
    width: 60px;
    text-align: center;
    display: block;
    margin-right: 10px;
    float: left;
}

.entry-cal-month {
    background-color: red;
    color: #fff;
    width: 100%;
    display: block;
    font-size: 15px;
    padding: 2px;
    box-sizing: border-box;
}

.entry-cal-day {
    display: block;
    margin-top: 4px;
    font-size: 20px;
}